import { PayloadAction } from '@reduxjs/toolkit'
import { CommunicationChannelLabel } from '@scudraservicos/coordinator-client/dist/src/services/applications/interfaces/CommunicationChannel.types'
import moment from 'moment'
import { call, delay, fork, put, race, take, takeEvery, takeLatest } from 'redux-saga/effects'
import { toaster } from '../../../App'
import { bffParceirosApiService } from '../../../services/bff'
import { PreCreditEvaluationResponse } from '../../../services/bff/borrowers/dto/Borrower.dto'
import { BorrowerData } from '../../../services/bff/coordinator/dto/Borrowers.dto'
import { TransactionalCreditEvaluation } from '../../../services/bff/transactional-credit/dtos/transactional-credit-evaluation.dto'
import BiometryLivenessReducer from '../biometry/biometry-liveness.reducer'
import ConfigurationsSliceReducer from '../configurations/configurations.reducer'
import { selectState } from '../selectors'
import { ApplicationErrorsMessage, transformHttpApplicationEngineApiErrorsToString } from './application.errors'
import ApplicationSliceReducer, {
  FetchTransactionalCreditEvaluation,
  IDeleteSecondaryPhone,
  IInsertSecondaryPhone,
  IPhoneNumberPayload,
  ISendVerificationCode,
  IStartPreApplicationPayload,
  IUpdateBorrowerBillDueDayPayload,
  IVerifyVerificationCode,
} from './application.reducer'
import { APPLICATION_STATUS, BiometryStatus, IPreApplicationResponse, PRE_APPLICATION_STATUS } from './application.types'

function* fetchPreApplication(action: PayloadAction<IStartPreApplicationPayload>) {
  try {
    const { cpf } = action.payload
    // TODO - Test if storeId is null
    const storeId = bffParceirosApiService.coordinator.getStore()?.id
    // const storeId = '1'
    if (!storeId) {
      // TODO - Shout NO_STORE_ID_CONFIGURED
      toaster.showErrorToast(`Loja não configurada`)
      return
    }

    yield put(ApplicationSliceReducer.actions.fetchPreApplicationLoading())
    yield put(BiometryLivenessReducer.actions.reset())

    const preApplicationResponse: IPreApplicationResponse = yield bffParceirosApiService.applicationEngine.preApplication({ cpf, storeId })
    preApplicationResponse.applicationVersion = yield selectState(state => state.configurations.configurations.application.version)
    yield put(ApplicationSliceReducer.actions.fetchPreApplicationSuccess(preApplicationResponse))

    // yield delay(500)
    if (
      preApplicationResponse.status === PRE_APPLICATION_STATUS.UNDER_ANALYSIS ||
      (preApplicationResponse.status === PRE_APPLICATION_STATUS.PRE_APPROVED && preApplicationResponse.biometryStatus !== BiometryStatus.CONFLICT)
    ) {
      yield put(ApplicationSliceReducer.actions.startApplicationLoading())
    }
  } catch (error) {
    yield put(ApplicationSliceReducer.actions.fetchPreApplicationError(error))
    const message = transformHttpApplicationEngineApiErrorsToString(error)
    toaster.showErrorToast(message)
    if (message == ApplicationErrorsMessage.DEACTIVATED_STORE) {
      yield put(ConfigurationsSliceReducer.actions.handleDeactivatedStore())
      yield put(ApplicationSliceReducer.actions.resetState())
    }
  }
}

function* sendVerificationCode(action: PayloadAction<ISendVerificationCode>) {
  try {
    const { phoneNumber } = action.payload
    const borrowerId: string = yield selectState(state => state.application.borrowerId)
    const phoneNumberWithCountryCode = `+55${phoneNumber}`
    const channel: CommunicationChannelLabel = yield selectState(state => state.application.selectedCommunicationChannel)
    yield put(ApplicationSliceReducer.actions.sendVerificationCodeLoading())

    yield bffParceirosApiService.borrowers.sendPrimaryPhoneVerificationCode(borrowerId, phoneNumberWithCountryCode, channel)

    yield delay(1000)
    yield put(ApplicationSliceReducer.actions.sendVerificationCodeSuccess({ phoneNumber: phoneNumber as string }))
  } catch (error) {
    yield put(ApplicationSliceReducer.actions.sendVerificationCodeError(error))
  }
}

function* sendPrimaryPhoneNumber(action: PayloadAction<IPhoneNumberPayload>) {
  try {
    const { phoneNumber } = action.payload
    const borrowerId: string = yield selectState(state => state.application.borrowerId)
    const phoneNumberWithCountryCode = `+55${phoneNumber}`

    yield bffParceirosApiService.borrowers.sendPrimaryPhone(borrowerId, phoneNumberWithCountryCode)

    yield put(ApplicationSliceReducer.actions.sendPhoneSuccess({ phoneNumber: phoneNumber as string }))
  } catch (error) {
    yield put(ApplicationSliceReducer.actions.sendPhoneError(error))
  }
}

function* insertSecondaryPhoneNumber(action: PayloadAction<IInsertSecondaryPhone>) {
  try {
    const { phoneNumber } = action.payload

    const phoneNumberWithCountryCode = `+55${phoneNumber}`
    const borrowerId = yield selectState(state => state.application.borrowerId)

    yield put(ApplicationSliceReducer.actions.insertSecondaryPhoneLoading())

    yield bffParceirosApiService.borrowers.createSecondaryPhoneNumber(borrowerId, phoneNumberWithCountryCode)

    yield put(ApplicationSliceReducer.actions.insertSecondaryPhoneSuccess({ phoneNumber }))
  } catch (error) {
    yield put(ApplicationSliceReducer.actions.insertSecondaryPhoneError(error))
  }
}

function* deleteSecondaryPhone(action: PayloadAction<IDeleteSecondaryPhone>) {
  try {
    const { phoneNumber, index } = action.payload

    const phoneNumberWithCountryCode = `+55${phoneNumber}`
    const borrowerId = yield selectState(state => state.application.borrowerId)

    yield put(ApplicationSliceReducer.actions.deleteSecondaryPhoneLoading(action.payload))

    yield bffParceirosApiService.borrowers.deleteSecondaryPhone(borrowerId, phoneNumberWithCountryCode)
    yield put(ApplicationSliceReducer.actions.deleteSecondaryPhoneSuccess({ index, phoneNumber }))
  } catch (error) {
    yield put(ApplicationSliceReducer.actions.deleteSecondaryPhoneError(error))
  }
}

function* verifyVerificationCode(action: PayloadAction<IVerifyVerificationCode>) {
  try {
    const { verificationCode } = action.payload
    const borrowerId = yield selectState(state => state.application.borrowerId)
    const phoneNumber = yield selectState(state => state.application.phoneNumber)
    const store = bffParceirosApiService.coordinator.getStore()

    const phoneNumberWithCountryCode = `+55${phoneNumber}`

    yield put(ApplicationSliceReducer.actions.verifyVerificationCodeLoading())

    const preCreditEvaluation: PreCreditEvaluationResponse = yield bffParceirosApiService.borrowers.verifyPrimaryPhoneVerificationCode(
      borrowerId,
      phoneNumberWithCountryCode,
      verificationCode,
      store?.id
    )
    yield put(ApplicationSliceReducer.actions.verifyVerificationCodeSuccess(preCreditEvaluation))
  } catch (error) {
    yield put(ApplicationSliceReducer.actions.verifyVerificationCodeError(error))
  }
}

function* updateBorrower(action: PayloadAction<undefined>) {
  try {
    const body = yield selectState(state => ({
      name: state.application.name,
      birthDate: state.application.birthDate,
      professionId: state.application.profession ? String(state.application.profession.id) : undefined,
      cep: state.application.cep,
      email: state.application.email,
    }))
    const borrowerId = yield selectState(state => state.application.borrowerId)

    // TODO - Where should this go?
    // What if is an invalid date?
    body.birthDate = moment(body.birthDate, 'DD/MM/YYYY').format('YYYY-MM-DD')

    yield put(ApplicationSliceReducer.actions.updateBorrowerLoading())

    const updatedBorrower = yield bffParceirosApiService.borrowers.updateBorrower(borrowerId, body)
    yield put(ApplicationSliceReducer.actions.updateBorrowerSuccess(updatedBorrower))
  } catch (error) {
    yield put(ApplicationSliceReducer.actions.updateBorrowerError(error))
  }
}

function* updateBorrowerBillDueDay(action: PayloadAction<IUpdateBorrowerBillDueDayPayload>) {
  try {
    const { billDueDay } = action.payload

    const borrowerId = yield selectState(state => state.application.borrowerId)
    yield put(ApplicationSliceReducer.actions.updateBorrowerBillDueDayLoading())
    const updatedBorrower = yield bffParceirosApiService.borrowers.updateBorrowerBillDueDay(borrowerId, billDueDay)
    yield put(ApplicationSliceReducer.actions.updateBorrowerBillDueDaySuccess(updatedBorrower))
  } catch (error) {
    yield put(ApplicationSliceReducer.actions.updateBorrowerBillDueDayError(error))
  }
}

function* startApplication(action: PayloadAction<undefined>) {
  try {
    const body = yield selectState(state => ({
      borrowerId: state.application.borrowerId,
      borrowerZipcode: state.application.cep,
      storeId: bffParceirosApiService.coordinator.getStore()?.id,
      // storeId: '1',
      professionId: String(state.application.profession?.id),
      // TODO - Is this necessary?
      requestedValue: 1,
      livenessCheckExecuted: !!state.biometryLiveness.biometryLivenessStatus,
    }))

    // TODO - On Error stop loading
    if (!body.storeId) {
      toaster.showErrorToast(`Erro - Loja não configurada`)
    }
    if (!body.professionId) {
      toaster.showErrorToast(`Erro - Profissão`)
    }

    yield bffParceirosApiService.applicationEngine.startApplicationEngine(body)
    yield put(ApplicationSliceReducer.actions.startApplicationLoading())
  } catch (error) {
    yield put(ApplicationSliceReducer.actions.startApplicationError(error))
    const message = transformHttpApplicationEngineApiErrorsToString(error)
    toaster.showErrorToast(message)
    if (message == ApplicationErrorsMessage.DEACTIVATED_STORE) {
      yield put(ConfigurationsSliceReducer.actions.handleDeactivatedStore())
      yield put(ApplicationSliceReducer.actions.resetState())
    }
  }
}

function* fetchBorrowerLimit(action: PayloadAction<undefined>) {
  try {
    const borrowerId = yield selectState(state => state.application.borrowerId)
    const store = bffParceirosApiService.coordinator.getStore()
    const storeId = store?.id
    const retailerId = store?.retailerId

    if (!storeId) {
      return toaster.showErrorToast(`Loja não configurada`)
    }

    yield put(ApplicationSliceReducer.actions.fetchBorrowerLimitLoading())

    const response: BorrowerData = yield bffParceirosApiService.coordinator.fetchBorrowerData(borrowerId, storeId, retailerId)
    yield put(ApplicationSliceReducer.actions.fetchBorrowerLimitSuccess(response))
  } catch (error) {
    yield put(ApplicationSliceReducer.actions.fetchBorrowerLimitError(error))
  }
}

function* poolApplicationStatus() {
  while (true) {
    try {
      const borrowerId = yield selectState(state => state.application.borrowerId)
      const applicationVersion = yield selectState(state => state.configurations.configurations.application.version)
      const response = yield bffParceirosApiService.applicationEngine.getApplicationStatus(borrowerId)
      const applicationStatus = response.status
      const biometryStatus = response.biometryStatus

      if (
        (applicationStatus === APPLICATION_STATUS.PRE_APPROVED && biometryStatus === BiometryStatus.CONFLICT) ||
        (applicationStatus !== APPLICATION_STATUS.UNDER_ANALYSIS && applicationStatus !== APPLICATION_STATUS.PRE_APPROVED)
      ) {
        yield put(
          ApplicationSliceReducer.actions.fetchApplicationStatusSuccess({
            applicationStatus,
            applicationVersion,
            biometryStatus,
          })
        )
        yield put(ApplicationSliceReducer.actions.restartApplicationLoading())
      }

      yield delay(1000)
    } catch (error) {
      yield put(ApplicationSliceReducer.actions.fetchApplicationStatusError(error))
    }
  }
}

function* poolApplicationStatusWatcher() {
  while (true) {
    yield take(ApplicationSliceReducer.actions.startApplicationLoading)
    yield race([call(poolApplicationStatus), take(ApplicationSliceReducer.actions.restartApplicationLoading)])
  }
}

function* fetchTransactionalCreditEvaluation(action: PayloadAction<FetchTransactionalCreditEvaluation>) {
  try {
    const cpf = action.payload.cpf
    const storeId = action.payload.storeId
    const retailerId = action.payload.retailerId
    const status = action.payload.status
    const results = action.payload.results

    if (!storeId || !retailerId) {
      return toaster.showErrorToast('Erro ao obter loja na verificação da Super Compra')
    }

    yield put(ApplicationSliceReducer.actions.fetchTransactionalCreditEvaluationLoading())

    const response: TransactionalCreditEvaluation = yield bffParceirosApiService.transactionalCredit.getAvailableEvaluation(cpf, storeId, retailerId, status, results)

    yield put(ApplicationSliceReducer.actions.fetchTransactionalCreditEvaluationSuccess(response))
  } catch (error) {
    yield put(ApplicationSliceReducer.actions.fetchTransactionalCreditEvaluationError(error))
  }
}

const applicationSaga = [
  takeLatest(ApplicationSliceReducer.actions.fetchPreApplication, fetchPreApplication),
  takeLatest(ApplicationSliceReducer.actions.sendVerificationCode, sendVerificationCode),
  takeLatest(ApplicationSliceReducer.actions.sendPrimaryPhoneNumber, sendPrimaryPhoneNumber),
  takeLatest(ApplicationSliceReducer.actions.insertSecondaryPhone, insertSecondaryPhoneNumber),
  takeEvery(ApplicationSliceReducer.actions.deleteSecondaryPhone, deleteSecondaryPhone),
  takeLatest(ApplicationSliceReducer.actions.verifyVerificationCode, verifyVerificationCode),
  takeLatest(ApplicationSliceReducer.actions.updateBorrower, updateBorrower),
  takeLatest(ApplicationSliceReducer.actions.updateBorrowerBillDueDay, updateBorrowerBillDueDay),
  takeLatest(ApplicationSliceReducer.actions.startApplication, startApplication),
  fork(poolApplicationStatusWatcher),
  takeLatest(ApplicationSliceReducer.actions.fetchBorrowerLimit, fetchBorrowerLimit),
  takeLatest(ApplicationSliceReducer.actions.fetchTransactionalCreditEvaluation, fetchTransactionalCreditEvaluation),
]

export default applicationSaga
